import { isAddress } from "@ethersproject/address"
import { useEffect, useState } from "react"
import { useBoolean } from "@chakra-ui/react"
import { BigNumber } from "@ethersproject/bignumber"

import { fetcher, GrpcErrorType } from "common/helpers/fetcher"
import {
  AssetType,
  type TokenBalance,
  type TokenBalances,
} from "common/types/tokenBalances"
import { getChainByReference } from "web3/helpers/findChain"
import type {
  TokenBalancesQuery,
  TokenBalancesQueryVariables,
} from "query/graphql"
import { TokenBalancesDocument } from "query/graphql"
import { getAccountId } from "web3/helpers/accountId"
import { shortString } from "common/helpers/string"

export const useTokenBalances = (
  chainReference: number,
  address: string,
  whitelistTokenAddresses: string[] = [],
): {
  isLoading: boolean
  tokenBalances: TokenBalances | undefined
  isError: boolean
} => {
  const [isLoading, { on, off }] = useBoolean(false)
  const [tokenBalances, setTokenBalances] = useState<TokenBalances | undefined>(
    undefined,
  )
  const [isError, setIsError] = useState<boolean>(false)

  const fetchCovalentData = () => {
    if (!address) return

    const foundChain = getChainByReference(chainReference)

    const hasCovalentSupport = foundChain?.covalentSupport === true

    // If no Covalent support, then set native current only without balance
    if (!hasCovalentSupport) {
      if (!foundChain?.nativeCurrency) return

      const { nativeCurrency } = foundChain

      const nativeAsset = {
        type: AssetType.NATIVE,
        rawBalance: "0",
        tokenInfo: {
          address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
          decimals: String(nativeCurrency.decimals),
          symbol: nativeCurrency.symbol,
          name: nativeCurrency.name,
          isDust: false,
          fiat: 0,
          fiat24H: 0,
          price: { rate: 0, rate24H: 0 },
          logo: "",
          balance24H: "0",
        },
      }
      setTokenBalances({ nativeAsset, tokens: [] })

      return
    }

    on()

    fetchTokenBalances(address, chainReference, whitelistTokenAddresses)
      .then((tokenBalances) => {
        setTokenBalances(tokenBalances as TokenBalances)
      })
      .catch(() => {
        setIsError(true)
      })
      .finally(() => off())
  }

  useEffect(() => {
    if (!isAddress(address)) return

    fetchCovalentData()

    return

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainReference, address])

  return { isLoading, tokenBalances, isError }
}

export type SourceTreasury = {
  chainReference: number
  address: string
  whitelistTokenAddresses: string[]
  name?: string
}

export const useMultiAddressTokenBalances = (
  sourceTreasuries: SourceTreasury[],
): {
  isLoading: boolean
  tokenBalances: TokenBalances | undefined
  isError: boolean
} => {
  const [isLoading, { on, off }] = useBoolean(false)
  const [tokenBalances, setTokenBalances] = useState<TokenBalances | undefined>(
    undefined,
  )
  const [isError, setIsError] = useState<boolean>(false)

  async function fetchAllTokenBalances(sourceTreasuries: SourceTreasury[]) {
    const promises = sourceTreasuries.map((sourceTreasury) => {
      const { address, chainReference, whitelistTokenAddresses } =
        sourceTreasury

      return fetchTokenBalances(
        address,
        chainReference,
        whitelistTokenAddresses,
      )
    })

    const multipleTokenBalances = await Promise.all(promises)

    return multipleTokenBalances
  }

  const fetchMultiAddressBalances = async () => {
    if (sourceTreasuries.length > 0) {
      on()

      try {
        const multipleTokenBalances = await fetchAllTokenBalances(
          sourceTreasuries,
        )

        // Filter out null values
        const validTokenBalances = multipleTokenBalances.filter(
          (tokenBalances): tokenBalances is TokenBalances =>
            tokenBalances !== null,
        )

        const tokenBalances = {
          nativeAsset: getCombinedNativeAsset(validTokenBalances),
          tokens: getCombinedTokens(validTokenBalances),
        }

        setTokenBalances(tokenBalances as TokenBalances)

        // If any of the token balances is null, set error
        const hasNullTokenBalance = multipleTokenBalances.some(
          (tokenBalance) => tokenBalance === null,
        )

        if (hasNullTokenBalance) {
          setIsError(true)
        }

        off()
      } catch (err) {
        setIsError(true)

        off()
      }
    }
  }

  useEffect(() => {
    fetchMultiAddressBalances()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceTreasuries])

  return { isLoading, tokenBalances, isError }
}

const fetchTokenBalances = (
  address: string,
  chainReference: number,
  whitelistTokenAddresses: string[] = [],
): Promise<TokenBalances | null> => {
  const chainId = `eip155:${chainReference}`
  const accountId = getAccountId({ address, chainId })

  const foundChain = getChainByReference(chainReference)
  const hasCovalentSupport = foundChain?.covalentSupport === true

  if (!hasCovalentSupport) {
    return Promise.resolve(null)
  }

  return fetcher
    .gql<TokenBalancesQuery, TokenBalancesQueryVariables>({
      query: TokenBalancesDocument,
      variables: {
        input: accountId,
      },
      omittedErrors: [GrpcErrorType.Internal],
    })
    .then((data) => {
      if (!data || !data.balances) {
        return null
      }

      const nativeAssetItem = data.balances.find((item) => item.nativeToken)
      const nativeAsset: TokenBalance = nativeAssetItem
        ? {
            type: AssetType.NATIVE,
            rawBalance: nativeAssetItem.balance ?? "0",
            tokenInfo: {
              address: nativeAssetItem.address,
              decimals: String(nativeAssetItem.decimals),
              price: {
                rate: nativeAssetItem.quoteRate ?? 0,
                rate24H: nativeAssetItem.quoteRate24H ?? 0,
              },
              symbol: nativeAssetItem.symbol,
              logo: nativeAssetItem.logo,
              name: nativeAssetItem.name,
              balance24H:
                nativeAssetItem.balance24H && nativeAssetItem.balance24H !== ""
                  ? nativeAssetItem.balance24H
                  : "0",
              fiat: nativeAssetItem.quote ?? 0,
              fiat24H: nativeAssetItem.quote24H ?? 0,
              isDust:
                nativeAssetItem.type === "dust" ||
                (nativeAssetItem.quote ?? 0) < 0.1,
            },
          }
        : {
            type: AssetType.NATIVE,
            rawBalance: "0",
            tokenInfo: {
              address: "",
              decimals: "0",
              price: {
                rate: 0,
                rate24H: 0,
              },
              symbol: "",
              logo: "",
              name: "",
              balance24H: "0",
              fiat: 0,
              fiat24H: 0,
              isDust: false,
            },
          }

      const tokens = data.balances
        .filter((item) => !item.nativeToken)
        .map(
          (item) =>
            ({
              type: AssetType.ERC20,
              rawBalance: item.balance,
              tokenInfo: {
                address: item.address,
                decimals: Boolean(item.decimals)
                  ? String(item.decimals)
                  : item.type === "dust"
                  ? "18"
                  : String(item.decimals),
                price: {
                  rate: item.quoteRate ?? 0,
                  rate24H: item.quoteRate24H ?? 0,
                },
                symbol: item.symbol,
                logo: item.logo,
                name: Boolean(item.name)
                  ? item.name
                  : shortString(item.address),
                balance24H: item.balance24H ?? item.balance ?? "0",
                fiat: item.quote ?? 0,
                fiat24H: item.quote24H ?? 0,
                isDust: item.type === "dust" || (item.quote ?? 0) < 0.1,
              },
            } as TokenBalance),
        )

      const tokenBalances: TokenBalances = {
        nativeAsset,
        tokens,
      }

      const getFilteredTokenBalances = (
        tokenBalances: TokenBalances,
        whitelistTokenAddresses?: string[],
      ) => {
        // clean tokens - keep only the ones that have USD value or
        // they are in the whitelist sent as param (i.e. governance tokens)
        if (tokenBalances) {
          const filteredTokens = tokenBalances.tokens?.filter(
            (token) =>
              Number(token.tokenInfo.fiat) > 0 ||
              (whitelistTokenAddresses &&
                whitelistTokenAddresses
                  .map((address) => address.toLowerCase())
                  .indexOf(token.tokenInfo.address.toLowerCase()) >= 0),
          )

          return { ...tokenBalances, tokens: filteredTokens }
        }

        return null
      }

      if (whitelistTokenAddresses.length > 0) {
        const filteredBalances = getFilteredTokenBalances(
          tokenBalances as TokenBalances,
          whitelistTokenAddresses,
        )

        return filteredBalances as TokenBalances
      } else {
        return tokenBalances as TokenBalances
      }
    })
    .catch(() => {
      return null
    })
}

const getCombinedNativeAsset = (multipleTokenBalances: TokenBalances[]) => {
  const combinedNativeAsset: TokenBalance = multipleTokenBalances[0].nativeAsset

  if (multipleTokenBalances.length > 1) {
    multipleTokenBalances.slice(1).forEach((tokenBalance: TokenBalances) => {
      combinedNativeAsset.rawBalance = BigNumber.from(
        combinedNativeAsset.rawBalance,
      )
        .add(tokenBalance.nativeAsset.rawBalance)
        .toString()

      combinedNativeAsset.tokenInfo.balance24H = BigNumber.from(
        combinedNativeAsset.tokenInfo.balance24H,
      )
        .add(tokenBalance.nativeAsset.tokenInfo.balance24H)
        .toString()

      combinedNativeAsset.tokenInfo.price.rate +=
        tokenBalance.nativeAsset.tokenInfo.price.rate

      combinedNativeAsset.tokenInfo.price.rate24H +=
        tokenBalance.nativeAsset.tokenInfo.price.rate24H

      combinedNativeAsset.tokenInfo.fiat +=
        tokenBalance.nativeAsset.tokenInfo.fiat

      combinedNativeAsset.tokenInfo.fiat24H +=
        tokenBalance.nativeAsset.tokenInfo.fiat24H
    })
  }

  return combinedNativeAsset
}

const getCombinedTokens = (multipleTokenBalances: TokenBalances[]) => {
  const tokens: TokenBalance[] = multipleTokenBalances
    .map((tokenBalance: TokenBalances) => tokenBalance.tokens || [])
    .flat()

  const combinedTokens = tokens.reduce((result, token) => {
    const tokenIndex = result.findIndex(
      (resultToken) =>
        resultToken.tokenInfo.address === token.tokenInfo.address,
    )

    if (tokenIndex >= 0) {
      const existingToken = {
        ...result[tokenIndex],
        rawBalance: BigNumber.from(result[tokenIndex].rawBalance || "0")
          .add(token.rawBalance)
          .toString(),
        tokenInfo: {
          ...result[tokenIndex].tokenInfo,
          balance24H: result[tokenIndex].tokenInfo.balance24H
            ? BigNumber.from(result[tokenIndex].tokenInfo.balance24H)
                .add(token.tokenInfo.balance24H)
                .toString()
            : token.tokenInfo.balance24H,
          fiat: result[tokenIndex].tokenInfo.fiat + token.tokenInfo.fiat,
          fiat24H:
            result[tokenIndex].tokenInfo.fiat24H + token.tokenInfo.fiat24H,
          price: {
            ...result[tokenIndex].tokenInfo.price,
            rate:
              result[tokenIndex].tokenInfo.price.rate +
              token.tokenInfo.price.rate,
            rate24H:
              result[tokenIndex].tokenInfo.price.rate24H +
              token.tokenInfo.price.rate24H,
          },
        },
      }
      result[tokenIndex] = existingToken

      return result
    } else {
      return [...result, token]
    }
  }, [] as TokenBalance[])

  return combinedTokens
}
